@use "../global" as g;

// ===========================================
// ページネーション WP-PageNaviに上書き
// ===========================================
.c-pagination {
  display: flex;
  justify-content: center;

  .wp-pagenavi {
    display: flex;
    justify-content: center;
  }
    
  .page,
  .previouspostslink,
  .nextpostslink,
  .current {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 0 4px;
    border-radius: 100vw;
    border: 1px solid g.$g-bg-blue;
    font-weight: bold;
    color: g.$g-bg-blue;
  }

  .page,
  .previouspostslink,
  .nextpostslink {
    transition: g.$g-transition-btn;
    box-shadow: g.$g-elevation03;

    &:hover {
      color: #fff;
      background-color: g.$g-bg-blue;
    }
  }

  .previouspostslink,
  .nextpostslink {
    background-position: center;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2215%22%20height%3D%2215%22%20viewBox%3D%220%200%2015%2015%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%230145aa%22%3E%3Crect%20x%3D%2210.0005%22%20y%3D%2212.8423%22%20width%3D%227.19417%22%20height%3D%221.42445%22%20transform%3D%22rotate(-135%2010.0005%2012.8423)%22%2F%3E%3Crect%20x%3D%2210.8486%22%20y%3D%223.16504%22%20width%3D%227.44676%22%20height%3D%221.42445%22%20transform%3D%22rotate(135%2010.8486%203.16504)%22%2F%3E%3Crect%20x%3D%226.41699%22%20y%3D%2212.8423%22%20width%3D%227.19417%22%20height%3D%221.42445%22%20transform%3D%22rotate(-135%206.41699%2012.8423)%22%2F%3E%3Crect%20x%3D%227.26514%22%20y%3D%223.16504%22%20width%3D%227.44676%22%20height%3D%221.42445%22%20transform%3D%22rotate(135%207.26514%203.16504)%22%2F%3E%3C%2Fsvg%3E');

    &:hover {
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2215%22%20height%3D%2215%22%20viewBox%3D%220%200%2015%2015%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23fff%22%3E%3Crect%20x%3D%2210.0005%22%20y%3D%2212.8423%22%20width%3D%227.19417%22%20height%3D%221.42445%22%20transform%3D%22rotate(-135%2010.0005%2012.8423)%22%2F%3E%3Crect%20x%3D%2210.8486%22%20y%3D%223.16504%22%20width%3D%227.44676%22%20height%3D%221.42445%22%20transform%3D%22rotate(135%2010.8486%203.16504)%22%2F%3E%3Crect%20x%3D%226.41699%22%20y%3D%2212.8423%22%20width%3D%227.19417%22%20height%3D%221.42445%22%20transform%3D%22rotate(-135%206.41699%2012.8423)%22%2F%3E%3Crect%20x%3D%227.26514%22%20y%3D%223.16504%22%20width%3D%227.44676%22%20height%3D%221.42445%22%20transform%3D%22rotate(135%207.26514%203.16504)%22%2F%3E%3C%2Fsvg%3E');
    }
  }

  .nextpostslink
  {
    transform: rotate(180deg);
  }

  .extend {
    margin: 0 4px;
    color: g.$g-border-gray2;
    font-family: 'Noto Sans JP';
    line-height: 40px;
  }

  .current {
    color: g.$g-text-gray;
    background-color: g.$g-border-gray1;
    border: none;
    box-shadow: none;
  }
}