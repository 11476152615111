@use "../global" as g;


// =========================================
// 記事詳細
// =========================================
.p-entry {
  padding: 0 g.$g-padding;
  @include g.mq() {
    padding: 0;
  }
}

.p-entry-inner {
  color: g.$g-text-blackright;

  @include g.mq() {

    width: g.vw(716);
    max-width: 716px;
    margin: 0 auto;
  }
}

// =========================================
// ブロックエディター用
// =========================================
.p-entryContents {


  // ====================================
  // テキスト系
  // ====================================

  p {
    letter-spacing: 0.6px;
    text-align: justify;
    font-size: 1.5rem;
    line-height: 1.7;
    margin-bottom: 40px;

    @at-root .en .p-entryContents p {
      text-align: left;
    }

    strong {
      display: inline-block;
      padding: 1px 2px;
      background-color: g.$g-bg-rightblue;
    }
    a {
      text-decoration: underline;
      color: g.$g-bg-blue;

      &:hover {
        text-decoration: none;
      }
    }
  }

  // 注意書き的な情報
  p.comment {
    color: g.$g-text-gray;
    font-size: 1.3rem;
    margin-top: 40px;
    margin-bottom: 40px;
  }



  // ====================================
  // 見出し系
  // ====================================
  h2 {
    position: relative;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 1.4;
    padding-bottom: 12px;
    margin-top: 52px;
    margin-bottom: 20px;

    &::before {
      content: "";
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: g.$g-border-gray1;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 49.702%;
      height: 3px;
      background-color: g.$g-bg-blue;
    }
  }

  h3 {
    position: relative;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1.4;
    padding-bottom: 8px;
    margin-top: 40px;
    margin-bottom: 20px;

    @include g.mq() {
      font-size: 2rem;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: g.$g-border-gray1;
    }
  }

  h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;

    @include g.mq() {
      font-size: 1.6rem;
    }
  }




  // ====================================
  // リスト系
  // ====================================
  ol, ul {
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      margin-bottom: 4px;
      a {
        text-decoration: underline;

        @include g.mq() {
          color: g.$g-bg-blue;
        }
      
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  ol {
    > li {
      counter-increment: number;

      &::before{
        content: counter(number) ".";
        color: g.$g-bg-blue;
        font-weight: bold;
        margin-right: 5px;
      }
    }
  }
  ul {
    > li {
      counter-increment: number;

      &::before{
        content: "";
        display: inline-block;
        font-weight: bold;
        width: 8px;
        height: 8px;
        margin-right: 5px;
        border-radius: 100vw;
        background-color: g.$g-bg-blue;
      }
    }
  }



  // ====================================
  // 引用系
  // ====================================
  blockquote {

    position: relative;
    text-align: left;
    padding: 40px 24px 40px 34px;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: g.$g-bg-rightblue;

    @include g.mq() {
      padding: 40px 34px 40px 50px;
    }

    p {
      margin-bottom: 20px;

      &:only-of-type {
        margin-bottom: 0;
      }
    }
    cite {
      color: g.$g-text-gray;
      font-style: normal;

      a {
        color: g.$g-text-gray;
      
        &:hover {
          text-decoration: none;
        }
      }
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      width: 2px;
      height: calc(100% - 80px);
      background-color: g.$g-text-blackright;

      @include g.mq() {
        left: 32px;
      }
    }
  }

  blockquote.no-border {
    padding: 36px 24px;

    @include g.mq() {
       padding: 32px;
    }

    &::before {
      display: none;
    }
  }



  // ====================================
  // 画像系
  // ====================================

  figure {
    text-align: center;
    img {
      height: auto;
      vertical-align: bottom;
    }
  }
  figcaption {
    display: block;
    font-size: 1.3rem;
    margin-top: 8px;
  }


  // 横画像 + テキスト（1カラム）
  .p-image-horizontal-one {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  // 縦画像 + テキスト（1カラム）
  .p-image-vertical-one {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;

    @include g.mq() {
      width: 456px;
      margin: 40px auto;
    }

    img {
      max-width: 71.04%;

      @include g.mq() {
        max-width: 456px;
      }
    }
  }


  // 横画像 + テキスト（2カラム）
  .p-image-horizontal-two {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 40px;

    @include g.mq() {
      flex-direction: row;
      gap: 12px;

      .wp-block-column {
        width: 50%;
      }
    }
  }

  
  // 縦画像 + テキスト（2カラム） {
  .p-image-vertical-two {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 40px;

    @include g.mq() {
      flex-direction: row;
      gap: 0;
      justify-content: space-around;

      .wp-block-column {
        width: 352px;

        figure {
          display: block;
          width: 240px;
          margin: 0 auto;
        }
      }
    }

    img {
      max-width: 71.04%;

      @include g.mq() {
        max-width: none;
        width: 240px;
      }
    }
  }


  // 回り込み画像 + テキスト
  .p-image-floatImage {
    .wp-block-image {
      margin-bottom: 40px;

      @include g.mq() {
        float: left;
        margin-bottom: 12px;

        figure {
          width: 352px;
          margin-right: 12px;
        }
      }
    }
  }

  //回り込み縦画像 + テキスト
  .p-image-floatImageVertical {
    .wp-block-image {
      margin-bottom: 40px;

      @include g.mq() {
        width: 352px;
        float: left;
        margin-bottom: 12px;
        margin-right: 12px;

        figure {
          display: block;
          width: 240px;
          margin: 0 auto;
        }
      }

      img {
        max-width: 71.04%;
        @include g.mq() {
          max-width: none;
          width: 240px;
        }
      }
    }
  }

}

// 前後の記事に移動するページャー
.p-entry-prevnext {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;
  margin-right: calc(50% - 50vw);
	margin-left: calc(50% - 50vw);
  border-top: 1px solid g.$g-border-gray1;

  @include g.mq() {
    padding: 24px 62px;
    margin: 80px 0 0;
  }
}
.p-entry-prevnextItem {
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1.4;

  a {
    position: relative;
    display: flex;
    align-items: center;
    color: g.$g-bg-blue;

    svg {
      fill: g.$g-bg-blue;
      margin-right: 8px;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
      transform-origin: center center;
      background-color: g.$g-bg-blue;
      transition: transform g.$g-transition-cubic-bezier;
    }

    // PC版ホバー
    @include g.mq() {
      &:hover {

        &::before {
          transform: scaleX(1);
        }
      }
    }
  }
}
.p-entry-prevnextItem--prev {
  a {
    flex-direction: row-reverse;

    &:before {
      transform-origin: center right;
    }
    &::after {
      content: "";
      fill: g.$g-bg-blue;
      width: 13px;
      height: 14px;
      margin-right: 8px;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2215%22%20height%3D%2215%22%20fill%3D%22%230145AA%22%20viewBox%3D%220%200%2015%2015%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M2%206.125L7.5%206.125L7.5%202L13%207.5L7.5%2013L7.5%208.875L2%208.875L2%206.125Z%22%2F%3E%3C%2Fsvg%3E');
      transform: rotate(180deg);
    }
  }
}
.p-entry-prevnextItem--next {
  a {
    &::before {
      transform-origin: center left;
    }
    &::after {
      content: "";
      fill: g.$g-bg-blue;
      width: 13px;
      height: 14px;
      margin-left: 8px;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2215%22%20height%3D%2215%22%20fill%3D%22%230145AA%22%20viewBox%3D%220%200%2015%2015%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M2%206.125L7.5%206.125L7.5%202L13%207.5L7.5%2013L7.5%208.875L2%208.875L2%206.125Z%22%2F%3E%3C%2Fsvg%3E');
    }
  }
}