@use "../global" as g;

.c-header-spBtn {
  margin-top: 10px;

  position: fixed;
  top: 12px;
  right: 12px;
  z-index: g.z(spBtn);
  display: block;
  width: 30px;
  height: 30px;
  padding: 4px;
  @include g.mq() {
    display: none;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: 'menu';
    text-transform: uppercase;
    font-size: .8rem;
    line-height: 1;
    @include g.mq() {
      display: none;
    }
  }
  &.is-open {
    .c-header-spBtnLine {
      background-color: transparent;
      &::before {
        top: 0;
        transform: rotate(45deg);
      }
      &::after {
        top: 0;
        transform: rotate(-45deg);
      }
    }
    &::after {
      content: 'close';
    }
  }
}
.c-header-spBtnLine {
  position: absolute;
  top: 6px;
  right: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 2px;
  margin: auto;
  &::before,
  &::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000000;
    transition: all .3s ease-in-out;
    content: '';
  }
  &::after {
    top: 0px;
  }
  &::before {
    top: 6px;
  }
}
@keyframes spNavOpen {
  0% {
opacity: 1;
    visibility: visible;
    width: calc(100vw - 32.2vw);
    transform: translateX(32.2vw);
  }
  100% {
    opacity: 1;
    visibility: visible;
    width: calc(100vw - 32.2vw);
    transform: translateX(32.2vw);
  }
}
.c-header-spNav {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: g.z(spNav);
  display: block;
  background-color: #FFFFFF;
  opacity: 0;
  box-shadow: g.$g-elevation03;
  width: calc(100vw - 32.2vw);
  transform: translateX(100vw);
  visibility: hidden;
  transition: all 0.8s cubic-bezier(0.71, 0, 0.13, 1.01);
  overflow-y: scroll;
  padding: 12px 24px;

  @include g.mq() {
    display: none;
  }

  &.is-open {
    opacity: 1;
    visibility: visible;
    transform: translateX(32.2vw);
    transition: all 0.8s cubic-bezier(0.71, 0, 0.13, 1.01);
  }
}
.c-header-spNavLogo {
  margin-bottom: 40px;
}
.c-header-spNavListNav {
  margin-bottom: 44px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
.c-header-spNavItem {
  margin-bottom: 20px;

//   @at-root .c-header-spNav.is-open .c-header-spNavItem {
//     transform: translateX(1200%);
//     animation-name: fadeIn;
//     animation-duration: 0.6s;
//     animation-fill-mode: forwards;
//     &:nth-child(1){ animation-delay: .2s; }
//     &:nth-child(2){ animation-delay: .3s; }
//     &:nth-child(3){ animation-delay: .4s; }
//     &:nth-child(4){ animation-delay: .5s; }
//     &:nth-child(5){ animation-delay: .6s; }
//     &:nth-child(6){ animation-delay: .7s; }
//     &:nth-child(7){ animation-delay: .8s; }
//     &:nth-child(8){ animation-delay: .9s; }
//     &:nth-child(9){ animation-delay:  1s; }
//   }
}
.c-header-spNavLink {
  position: relative;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 26px;
}

.c-header-lang {

  // TODO: 英語サイト制作時に復活
  // display: none;

  padding-top: 16px;
  border-top: 1px solid g.$g-border-gray1;

  @include g.mq() {
    width: 50px;
    padding-top: 0;
    border-top: none;
  }
}
.c-header-langList {
  display: flex;
  justify-content: center;
  align-items: center;
  @include g.mq() {
    justify-content: flex-end;
  }
}
.c-header-langItem {
  position: relative;

  &:first-of-type {
    margin-right: 7px;
    @include g.mq() {
      margin-right: 2px;
    }

    &::after {
      content: "/";
      display: inline-block;
      color: g.$g-text-gray;
      margin-left: 5px;
      @include g.mq() {
        margin-left: 4px;
      }
    }
  }

  a {
    color: g.$g-text-gray;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 26px;

    @include g.mq() {
      font-size: 1.3rem;
      line-height: 36px;
    }

    &.is-current {
      color: g.$g-bg-green;
    }
  }
}


// =============================================
// カレント状態
// .is-currentは開発環境用、[aria-current]はWPでの実装時用
// =============================================

.c-header-navLink,
.c-header-spNavLink {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: transparent;
    transform: scaleX(0);
    transform-origin: center left;
    transition: transform g.$g-transition-cubic-bezier;
  }

  // カレント状態
  &.is-current,
  &[aria-current] {
    color: g.$g-bg-red;
    &::after {
      transform: scaleX(1);
      background-color: g.$g-bg-red;
    }
  }

  // PC版ホバー
  @include g.mq() {
    &:hover {

      &::after {
        transform: scaleX(1);
        background-color: g.$g-bg-blue;
      }
    }
  }
}


// =============================================
// PC版メニュー
// =============================================

.c-header-nav {
  display: none;
  @include g.mq() {
    display: flex;
    justify-content: flex-end;

    // TODO: 英語サイト制作時に復活
    justify-content: space-between;

    width: g.vw(722);
    max-width: 722px;
    margin-top: -12px;

    @at-root .en .c-header-nav {
      @include g.mq() {
        width: 48.851852vw;
        max-width: 600px;
      }
    }
  }
}
@include g.mq() {
  .c-header-navList {
    display: flex;
    justify-content: space-between;
    width: g.vw(636);
    max-width: 636px;

    @at-root .en .c-header-navList {
      @include g.mq() {
        width: 39.888889vw;
        max-width: 500px;
      }
    }
  }
  .c-header-navItem {

  }
  .c-header-navLink {
    display: block;
    color: g.$g-text-primary;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 36px;

    // &.is-current {
    //   position: relative;

    //   &::after {
    //     content: "";
    //     position: absolute;
    //     bottom: -3px;
    //     left: 0;
    //     display: block;
    //     width: 100%;
    //     height: 2px;
    //     background-color: g.$g-bg-red;
    //   }
    // }
  }
}