@use "../global" as g;

// ===========================================
// 第2階層以降
// ===========================================
.c-contHead {
  position: relative;
  height: 206px;
  padding: 88px g.$g-padding 0;
  margin-bottom: 30px;
  background: url("../images/header_contents_mv_sp.svg") bottom center no-repeat;
  background-size: cover;

  @media screen and (min-width: 750px) {
    min-height: 334px;
    margin-bottom: 40px;
    background: url("../images/header_contents_mv_pc.svg") bottom center no-repeat;
    background-size: cover;
  }
  @include g.mq() {
    min-height: 334px;
    margin-bottom: 40px;
    background: url("../images/header_contents_mv_pc.svg") bottom center no-repeat;
    background-size: cover;
  }
}
.c-contHead-title {
  position: absolute;
  bottom: 28px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: g.$g-text-nabyblue;
  font-size: 2.5rem;
  line-height: 1.4;
  font-weight: bold;
  width: calc(100% - 40px);

  @include g.mq() {
    bottom: 60px;
    font-size: 3.2rem;
  }
}
.c-contHead-titleHeadline {
  display: block;
  text-align: center;
  position: relative;
  letter-spacing: 1.5px;

  &::after {
    content: "";
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 20px;
    background-color: g.$g-bg-orange;

    @include g.mq() {
      bottom: -66px;
      height: 48px;
    }
  }
}
.c-contHead-titlesubLine {
  display: block;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.7;
  color: g.$g-text-primary;
}

// ===============================================================
// 記事詳細
// ===============================================================
.c-contHead--entry {
  height: auto;
  background: url("../images/header_news_mv_sp.svg") bottom center no-repeat;
  background-size: 100%;


  @media screen and (min-width: 750px) {
    height: auto;
    margin-bottom: 40px;
    background: url("../images/header_news_mv_pc.svg") bottom center no-repeat;
    background-size: 100%;
  }
  @include g.mq() {
    background: url("../images/header_news_mv_pc.svg") bottom center no-repeat;
    background-size: cover;
  }

  .c-breadcrumb-item {
    &:last-of-type {
      display: none;
    }
  }


  .c-contHead-title {
    position: static;
    bottom: auto;
    left: auto;
    transform: translateX(0);
    text-align: left;
    font-size: 2.4rem;
    width: calc(100% - 10vw);

    @include g.mq() {
      font-size: 2.4rem;
    }
  }
  .c-contHead-titleHeadline {
    display: block;
    position: static;
    letter-spacing: 0;

    &::after {
      display: none;
    }
  }
}


.p-entryHead {


  .c-contHead-titleHeadline {
    text-align: justify;
  }
  @include g.mq() {
    width: g.vw(716);
    max-width: 716px;
    margin: 56px auto 0;

    .p-entryHead {
      font-size: 2.4rem;
    }
    .c-contHead-title {
      width: 100%;
    }
  }
}
.p-entryInfo {
  display: flex;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 12px;

  @include g.mq() {
    margin-top: 0;
    margin-bottom: 20px;
  }
}
.p-entryInfoTime {
  font-size: 1.3rem;
  color: g.$g-text-gray;
  width: 72px;
  margin-right: 12px;
}
.p-entryInfoCate {
  cursor: default;
  color: #ffffff;
  text-align: center;
  line-height: 24px;
  font-size: 1.1rem;
  font-weight: bold;
  width: 68px;
  height: 24px;
  border-radius: 100vw;

  &--all {
    background-color: g.$g-bg-blue;
  }
  &--offering {
    background-color: g.$g-bg-green;
  }
  &--general {
    color: g.$g-text-blackright;
    background-color: g.$g-bg-orange;
  }
  &--event {
    background-color: g.$g-bg-red;
  }
}