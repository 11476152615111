@use "global" as g; 

* {
  text-align: justify;

  @at-root .en * {
    text-align: left;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: g.$g-font-serif;
  font-size: 1.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "palt" 1;
  font-feature-settings: "palt" 1;
  text-rendering: optimizeLegibility;
  line-height: 1.7;
  touch-action: manipulation;

  &.is-navOpen {
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    &::after {
      content: '';
      height: 100vh;
      overflow: hidden;
      position: fixed;
      top: 0;
      z-index: g.z(is-navOpenBlur);
      width: 100%;
      animation: backdrop-filter 0.6s forwards;
    }
    @include g.mq() {
      display: none;
    }
  }
}
@keyframes backdrop-filter {
  0% {
    width: 100%;
    backdrop-filter: blur(0px);
  }
  100% {
    width: 120%;
    backdrop-filter: blur(6px);
  }
}


h1, h2, h3 ,h4, h5, h6 {
  margin: 0;
  font-weight: normal;
  @include g.mq() {
  }
}

p {
  margin: 0;
}

ul, ol, dl, dd {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  color: g.$g-bg-blue;
  text-decoration: none;
  @include g.mq() {
    color: g.$g-text-primary;

    &:hover {
      opacity: 0.925;
    }
  }
}

img {
  max-width: 100%;
}

button {
  padding: 0;
  border: none;
  outline: none;
}

input {
  border: 0;
  padding: 0;
  outline: none;
  color: g.$g-text-primary;
}

select {
  outline: none;
}

textarea {
  resize: none;
}

#icons {
  display: none;
}

.is-pc {
  @include g.mq() {
    display: none;
  }
}

.is-sp {
  display: none;
  @include g.mq() {
    display: block;
  }
}


