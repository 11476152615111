@use "../global" as g;

// =========================================
// メンバー専用 パスワードログイン画面
// =========================================
.p-staffLogin {
  padding: 0 g.$g-padding;
  @include g.mq() {
    padding: 0;
  }
}

.p-staffLogin-inner {
  @include g.mq() {
    width: g.vw(716);
    max-width: g.$g-width-sm;
    margin: 0 auto;
  }
}


.p-staffLogin-login {
  @include g.mq() {
    margin-top: 120px;
  }
}

.p-staffLogin-loginDesc {
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.p-staffLogin-loginForm {
  display: flex;
  gap: 8px;

  @include g.mq() {
    width: 534px;
    margin: 0 auto;
  }
}

.p-staffLogin-loginInput {
  flex-grow: 1;
  padding: 10px 8px;
  border-radius: 2px;
  background-color: g.$g-bg-whitegray;
  border: 1px solid g.$g-border-gray2;

    &::placeholder {
      font-size: 1.4rem;
      color: g.$g-text-gray;
    }
}
.p-staffLogin-loginBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  font-size: 1.4rem;
  font-weight: bold;
  width: 82px;
  color: #FFFFFF;
  border-radius: 2px;
  background-color: g.$g-bg-blue;
  box-shadow: g.$g-elevation01;

  &::after {
    content: "";
    display: block;
    width: 11.2px;
    height: 11.2px;
    margin-left: 8px;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2215%22%20height%3D%2215%22%20viewBox%3D%220%200%2015%2015%22%20fill%3D%22%23FFFFFF%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M2%206.125L7.5%206.125L7.5%202L13%207.5L7.5%2013L7.5%208.875L2%208.875L2%206.125Z%22%2F%3E%3C%2Fsvg%3E');
    background-size: contain;
  }
}