@use "../global" as g;


.c-header {
  @include g.mq() {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: g.z(c-headerPC);
    height: 125px;
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%);
  }
}
.c-header-inner {
  @include g.mq() {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: g.vw(1048);
    max-width: 1080px;
    padding-top: 24px;
    margin: 0 auto;
  }
}
.c-header-logo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: g.z(c-header-logo);
  padding: 12px;
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%);
  // backdrop-filter: blur(6px);

  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include g.mq() {
    position: static;
    padding: 0;
    background: transparent;
    // backdrop-filter: blur(0);
  }
}
.c-header-logoMark {
  flex-shrink: 0;
  width: 53px;
  margin-right: 8px;

  @at-root .en .c-header-logoMark {
    width: 42px;

    @include g.mq() {
      width: 53px;
    }
  }
}
.c-header-logoDescription {
  flex-shrink: 1;
  width: 62.4%;
  max-width: 234px;
  margin-top: 2px;

  @include g.mq() {
    width: 100%;
    flex-shrink: 0.5;
  }

  @at-root .en .c-header-logoDescription {
    width: 73%;
    max-width: 400px;

    @include g.mq() {
      width: 100%;
    }
  }
}