// .p-gCalendar {
//   position: relative;
//   padding-bottom: 80.25%;
//   padding-top: 30px;
//   height: 0;
//   overflow: hidden;
// }
   
// .p-gCalendar iframe,
// .p-gCalendar object,
// .p-gCalendar embed {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//  }

@use "../global" as g;

#calendar.fc {

  a {
    color: g.$g-text-blackright;
  }

  // ツールバー
  .fc-header-toolbar {
    margin-bottom: 18px;
    flex-wrap: wrap;
  }
  .fc-toolbar-title {
    font-size: 18px;
    font-weight: bold;
  }
  .fc-button {
    font-size: 11px;
    padding: 6px 10px;

    .fc-icon {
      font-size: 11px;
      width: 11px;
      height: 11px;
      line-height: 9px;
    }
  }
  .fc-button-primary {
    border-color: #ffffff;
    background-color: g.$g-bg-blue;
  }
  .fc-button-active {
    background-color: rgba( g.$g-bg-blue, 0.7 );
  }

  //日付
  .fc-daygrid-day-number {
    font-size: 11px;
  }

  //曜日
  .fc-scrollgrid-sync-inner {
    text-align: center;
    font-size: 14px;
  }
  .fc-col-header-cell-cushion {
    padding: 1px 4px;
  }
  // .fc-daygrid-day-frame {
  //   min-height: 78px;
  // }
  .fc-daygrid-block-event {
    padding: 0 4px;
  }
  .fc-daygrid-event-harness {
    overflow: hidden;
  }
}