@use "../global" as g;

// =========================================
// メンバー専用 ログイン後
// =========================================
.p-staff {
  padding: 0 g.$g-padding;
  @include g.mq() {
    padding: 0;
  }
}

.p-staff-inner {
  @include g.mq() {
    width: g.vw(716);
    max-width: g.$g-width-sm;
    margin: 0 auto;
  }
}

.p-staff-section {

  & + & {
    margin-top: 40px;

    @include g.mq() {
      margin-top: 80px;
    }
  }
}
.p-staff-title {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.4;
  padding-bottom: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid g.$g-border-gray1;

  @include g.mq() {
    font-size: 2.4rem;
    padding-bottom: 0;
    margin-bottom: 10px;
    border-bottom: none;
  }

}
.p-staff-desc {
  color: g.$g-text-blackright;
  letter-spacing: 0.4px;
  margin-bottom: 12px;
}
.p-staff-linkList {

}
.p-staff-linkItem {
  position: relative;
  line-height: 1.4;
  padding-left: 12px;

  &::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 0;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background-color: g.$g-bg-blue;
  }

  a {
    text-decoration: underline;

    @include g.mq() {
      color: g.$g-bg-blue;

      &:hover {
        text-decoration: none;
      }
    }
  }
}