@use "../global" as g;

.c-footer {
  position: relative;
  padding: 166px g.$g-padding 32px;
  background: url("../images/decoration/bg_grid02.svg") top center no-repeat;
  background-size: cover;

  @include g.mq() {
    z-index: 0;
    padding: 340px 0 32px;
    background-position: top right;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 56px;
    left: 2.4vw;
    z-index: 0;
    width: 184px;
    height: 140px;
    background: url("../images/illust_03.svg") 0 0 no-repeat;
    background-size: contain;

    @include g.mq() {
      width: 237px;
      height: 140px;
      bottom: 15px;
      left: 1.3vw;
      background-size: auto;
    }
  }
}
.c-footer-inner {
  @include g.mq() {
    width: g.vw(1048);
    max-width: 1080px;
    margin: 0 auto;
  }

}
.c-footer-siteOverview {
  @include g.mq() {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
  }
}
.c-footer-siteOverview-inner {

  @include g.mq() {
    display: flex;
    align-items: center;
  }
}

.c-footer-siteLogo {
  text-align: center;
  margin-bottom: 32px;

  @include g.mq() {
    margin-bottom: 0;
  }

  img {
    width: 31.2%;
    max-width: 100px;
    
    @include g.mq() {
      width: g.vw(170);
      max-width: 100px;
    }
  }
}
.c-footer-siteDesc {
  margin-bottom: 48px;
  @include g.mq() {
    margin: 0 24px;
  }
}
.c-footer-siteDescHead {
  font-size: 1.1rem;
  margin-bottom: 2px;
}
.c-footer-siteDescBody {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 2px;

  br {
    @include g.mq() {
      display: none;
    }
  }
}
.c-footer-siteDescFoot {
  font-size: 1.1rem;
  color: g.$g-text-gray;
}

.c-footer-siteContact {
  padding: 18px 20px;
  margin-bottom: 48px;
  background-color: #ffffff;

  @include g.mq() {
    position: relative;
    width: g.vw(320);
    max-width: 320px;
    margin-bottom: 0;

    &::before {
      content: "";
      position: absolute;
      top: -92px;
      right: 22px;
      width: 86px;
      height: 126px;
      background: url("../images/illust_02.svg");
    }
  }
}
.c-footer-siteContactTtl {
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: 4px;
}
.c-footer-siteContactDesc {
  font-size: 1.2rem;

  br {
    @media screen and (max-width:1035px) {
      display: none;
    }
    @media screen and (min-width: 1035px) {
      display: block;
    }
  }
}
.c-footer-siteContactMail {
  a {
    text-decoration: underline;

    @include g.mq() {
      color: g.$g-bg-blue;
    }
  }
}

.c-footer-banner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 18px;
  margin-bottom: 156px;

  @include g.mq() {
    gap: 36px;
    margin-bottom: 130px;
  }
}
.c-footer-bannerItem {
  width: 42.13%;
  max-width: 200px;

  @include g.mq() {
    width: g.vw(336);
    max-width: 336px;
  }

  a {
    display: block;
    box-shadow: g.$g-elevation01;
    > img {
      vertical-align: bottom;
    }
  }
}
.c-footer-noLink {
  pointer-events: none;
}
.c-footer-copyright {
  display: block;
  font-size: 1.1rem;
  line-height: 1;
  text-align: center;
}



.c-pagetop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: g.z(c-pagetop);

  .c-btn-circle {
    width: 40px;
    height: 40px;
  }
  svg {
    transform: rotate(-90deg);
  }
}