@use "variable" as v;

/**
 * z-index
 */
$z-map: (
  top-news-title:         true,
  c-header-logo:          true,
  p-member-OpenBtn:       true,

  c-pagetop:              true,
  is-navOpenBlur:         true,
  spNav:                  true,
  spBtn:                  true,

  c-headerPC:             true,
);
// 序列が上に行くほど、z-indexの数値が低くなる（レイヤーが低くなる）

// .example {
//   z-index: g.z(c-nav);
// }

// z-index Function
@function z($name, $childname: 0) {
  $getkey: map-get($z-map, $name);
  @if $childname != 0 {
    @return index($getkey, $childname);
  }
  @return inspect(index($z-map, ($name $getkey) ));
}

/**
 * Mediaquerys.
 */
$breakpoints: (
  'maxsm': 'screen and (max-width: 500px)',
  'maxmd': 'screen and (max-width: 949px)', // <- When SmartPhone view
  'sm': 'screen and (min-width: 400px)',
  'md': 'screen and (min-width: 950px)', // <- Default value
  'lg': 'screen and (min-width: 1080px)',
  'xlg': 'screen and (min-width: 1440px)',
  'md-lg': 'screen and (min-width:950px) and ( max-width:1440px)',
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin mq-lg($breakpoint: lg) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// VW
$VWbaseWidth : 1080;
@function vw($pixelsVw) {
  @return $pixelsVw / $VWbaseWidth * 100vw;
}

// Hover & Focus
@mixin hover($ie: false) {
  &:focus {
    @content;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
  @if $ie {
    @at-root .ua-ie &:hover {
      @content;
    }
  }
}