@use "../global" as g;


// =========================================
// お知らせ一覧 すべて
// =========================================
.p-news {
  padding: 0 g.$g-padding;
  @include g.mq() {
    padding: 0;
  }
}

.p-news-inner {
  @include g.mq() {
    display: flex;
    justify-content: space-between;

    width: g.vw(898);
    max-width: 898px;
    margin: 0 auto;
  }
}

.p-news-headCateWrap {
  // padding-bottom: 22px;
  margin-right: calc(50% - 50vw);
	margin-left: calc(50% - 50vw);

  @include g.mq() {
    flex-shrink: 0;
    width: 152px;
    padding-top: 36px;
    margin: 0;
  }
}
.p-news-headCateList {
  overflow:hidden;
  overflow-x: scroll;
  white-space: nowrap;

  display: flex;
  padding: 0 20px;

  @include g.mq() {
    display: block;
    overflow: visible;
    overflow-x: visible;
    padding: 0;
  }
}
.p-news-headCateItem {
  padding-top: 2px;
  padding-bottom: 22px;
  @include g.mq() {
    padding: 0;
  }

  &:last-of-type {
    padding-right: 20px;
  }
  & + & {
    margin-left: 8px;

    @include g.mq() {
      margin-left: 0;
      margin-top: 12px;
    }
  }
}
.p-news-headCateLink {
  display: block;
  white-space: nowrap;
  color: #ffffff;
  font-weight: bold;
  line-height: 36px;
  text-align: center;
  width: 90px;
  height: 36px;
  border-radius: 100vw;
  box-shadow: g.$g-elevation02;
  
  @include g.mq() {
    width: g.vw(102);
    max-width: 102px;
    transition: g.$g-transition-btn;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: calc(100% - 12px);
      transform: translateY(-50%);
      display: block;
      width: 0px;
      height: 2px;
      transition: width g.$g-transition-cubic-bezier;
    }
  }

  &.is-active,
  &:hover {
    box-shadow: none;
    border-width: 1px;
    border-style: solid;;
    background-color: #ffffff;

    @include g.mq() {
      position: relative;
      
      &::after {
        width: 60px;
      }
    }
  }

  &--all {
    background-color: g.$g-bg-blue;
    &.is-active,
    &:hover {
      color: g.$g-bg-blue;
      border-color: g.$g-bg-blue;

      @include g.mq() {
        &::after {
          background-color: g.$g-bg-blue;
        }
      }
    }
  }
  &--offering {
    background-color: g.$g-bg-green;
    &.is-active,
    &:hover {
      color: g.$g-bg-green;
      border-color: g.$g-bg-green;
      
      @include g.mq() {
        &::after {
          background-color: g.$g-bg-green;
        }
      }
    }
  }
  &--general {
    color: g.$g-text-blackright;
    background-color: g.$g-bg-orange;
    &.is-active,
    &:hover {
      color: g.$g-text-blackright;
      border-color: g.$g-bg-orange;
      
      @include g.mq() {
        &::after {
          background-color: g.$g-bg-orange;
        }
      }
    }
  }
  &--event {
    background-color: g.$g-bg-red;
    &.is-active,
    &:hover {
      color: g.$g-bg-red;
      border-color: g.$g-bg-red;
      
      @include g.mq() {
        &::after {
          background-color: g.$g-bg-red;
        }
      }
    }
  }
}


.p-news-entryListWrap {
  padding-top: 20px;
  border-top: 1px dashed g.$g-border-gray1;

  @include g.mq() {
    width: 79.73%;
    max-width: 716px;
    padding-top: 28px;
  }
}
.p-news-entryList {
  margin-bottom: 40px;
}
.p-news-entryItem {
  padding-bottom: 20px;
  border-bottom: 1px dashed g.$g-border-gray1;

  @include g.mq() {
    display: flex;
    gap: 56px;
    padding-bottom: 28px;
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;

    @include g.mq() {
      margin-bottom: 28px;
    }
  }
}
.p-news-entryInfo {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  @include g.mq() {
    margin-bottom: 0;
  }
}
.p-news-entryTime {
  font-size: 1.3rem;
  color: g.$g-text-gray;
  width: 72px;
  margin-right: 24px;
}
.p-news-entryCate {
  cursor: default;
  color: #ffffff;
  text-align: center;
  line-height: 24px;
  font-size: 1.1rem;
  font-weight: bold;
  width: 68px;
  height: 24px;
  border-radius: 100vw;

  &--all {
    background-color: g.$g-bg-blue;
  }
  &--offering {
    background-color: g.$g-bg-green;
  }
  &--general {
    color: g.$g-text-blackright;
    background-color: g.$g-bg-orange;
  }
  &--event {
    background-color: g.$g-bg-red;
  }
}
.p-news-entryText {

  a {
    color: g.$g-text-blackright;
    text-decoration: underline;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;

    @include g.mq() {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

// =========================================
// お知らせ一覧 カテゴリー
// =========================================







// =========================================
// ページネーション
// =========================================
.p-pagenation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.133%;

  @include g.mq() {
    gap: 8px;
  }
  
  a.page-numbers,
  a.prev,
  a.next,
  .current {
    color: g.$g-bg-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    border-radius: 100vw;
    border: 1px solid g.$g-bg-blue;

    svg {
      fill: g.$g-bg-blue;
    }
  }

    a.page-numbers,
    a.prev,
    a.next {
      transition: g.$g-transition-btn;
      box-shadow: g.$g-elevation03;

      &:hover {
        color: #ffffff;
        background-color: g.$g-bg-blue;
        svg {
          fill: #ffffff;
        }
      }
    }

  .dots {
    color: g.$g-border-gray2;
    font-family: 'Noto Sans JP';
  }

  .current {
    color: g.$g-text-gray;
    background-color: g.$g-border-gray1;
    border: none;
    box-shadow: none;
  }
}