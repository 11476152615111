@use "../global" as g;


// =========================================
// 領域概要
// =========================================
.p-overview {
  padding: 0 g.$g-padding;

  @include g.mq() {
    padding: 0;
  }
}
.p-overview-inner {
  @include g.mq() {
    width: 716px;
    margin: 0 auto;
    padding: 0;
  }
}
.p-overview-hgroup {
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 40px;

  &::before {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: g.$g-border-gray1;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 224px;
    height: 5px;
    background: g.$g-graduation_all;
  }
}
.p-overview-hgroupHead {
  font-size: 1.1rem;
  color: g.$g-text-gray;
  margin-bottom: 6px;

  @include g.mq() {
    font-size: 1.3rem;
  }
}
.p-overview-hgroupTtl {
  font-size: 2.2rem;
  font-weight: bold;
  color: g.$g-text-nabyblue;
  line-height: 1.4;
  margin-bottom: 4px;

  @include g.mq() {
    font-size: 2.4rem;
  }
}
.p-overview-hgroupFoot {
  font-size: 1.1rem;
  color: g.$g-text-gray;

  @include g.mq() {
    font-size: 1.3rem;
  }
}

.p-overview-text {
  margin-bottom: 40px;

  a {
    color: g.$g-bg-blue;
    text-decoration: underline;
    @include g.mq() {
      &:hover {
        text-decoration: none;
      }
    }
  }
}


// ========================================================
// 湊先生
// ========================================================
.p-overview-professorIntro {
  margin-bottom: 80px;

  @include g.mq() {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    margin-top: 60px;
  }
}
.p-overview-professorIntroHead {
  margin-bottom: 12px;

  @include g.mq() {
    flex-shrink: 0;
    width: 280px;
    margin-bottom: 0;
  }
}
.p-overview-professorName {
  display: flex;
  align-items: center;

  @include g.mq() {
    flex-shrink: 0;
  }

  figcaption {
    display: flex;
    align-items: center;

    @at-root .en .p-overview-professorName figcaption {
      display: block;
    }
    @at-root .en .p-overview-professorName figcaption > * {
      display: block;
    }

    @include g.mq() {
      flex-shrink: 0;
    }

    > span {
      font-size: 1.3rem;
      margin-right: 8px;

      @include g.mq() {
        flex-shrink: 0;
        margin-right: 12px;
      }
    }
    > a {
      font-size: 2.2rem;
      font-weight: bold;

      @include g.mq() {
        color: g.$g-bg-blue;
      }

      span {
        text-decoration: underline;
      }

      &:hover {
        span {
          text-decoration: none;
        }
      }

      svg {
        width: 18px;
        height: 16px;
        fill: g.$g-bg-blue;
      }
    }
  }
}
.p-overview-professorAvatar {
  margin-right: 8px;

  @include g.mq() {
    flex-shrink: 0;
    margin-right: 12px;
  }
  
  img {
    width: 80px;
    height: 80px;
    border-radius: 100vw;
  }
}
.p-overview-professorIntroText {
  @include g.mq() {
    font-size: 1.3rem;
  }
}


// ========================================================
// 本研究領域の概念図
// ========================================================
.p-overview-image {

  @include g.mq() {
    width: g.vw(898);
    max-width: 898px;
    margin: 0 auto;
  }

  > .p-overview-imageColumnItem {
    margin-bottom: 0;
  }
}
.p-overview-imageColumn {
  @include g.mq() {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
  }
}
.p-overview-imageTtl {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 12px;

  @include g.mq() {
    font-size: 2.2rem;
  }
}
.p-overview-imageImg {
  position: relative;
  margin-bottom: 80px;
}

.p-overview-imageColumnItem {
  text-align: center;
  margin-bottom: 80px;

  img {
    vertical-align: bottom;
  }

  @include g.mq() {
    flex-shrink: 0;
    margin-bottom: 0;
  }
}
.p-overview-imageColumnItem-left {
  @include g.mq() {
    width: g.vw(261);
    max-width: 261px;
  }
}
.p-overview-imageColumnItem-right {
  @include g.mq() {
    width: g.vw(534);
    max-width: 534px;
  }
}
.p-overview-imageColumnTtl {
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 8px;

  @at-root .p-overview-imageColumnTtl {
    @include g.mq() {
      font-size: 2rem;
      // text-align: left;
      white-space: nowrap;
    }
  }

  br {
    @include g.mq() {
      display: none;
    }
  }
}
.p-overview-imageColumnText {
  a {
    color: g.$g-bg-blue;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
.p-overview-imageColumnText,
.p-overview-imageColumnFileinfo {
  font-size: 1.1rem;
  display: block;
  font-weight: normal;
  text-align: center;
  margin-bottom: 12px;

  @at-root .en .p-overview-imageColumnText {
    @include g.mq() {
      text-align: left;
    }
  }
}
.p-overview-imageColumnFileinfo {
  @include g.mq() {
    display: inline-block;
    font-size: 1.3rem;
    margin-left: 4px;
    margin-bottom: 0;
  }
}
.p-overview-imageColumnImg {
  position: relative;
  // display: block;

  &--row {
    width: 52%;
    max-width: 240px;
    margin: 0 auto;

    @include g.mq() {
      width: 100%;
      max-width: 260px;
    }
  }
}
.p-overview-imageColumnIcon {
  position: absolute;
  bottom: 20px;
  right: 20px;
}


// ===========================================
// Youtube用
// ===========================================
.c-youtube {
  width:100%;
  padding-bottom: 56.25%;
  height:0px;
  position: relative;
}
.c-youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

