@use "../global" as g;


// ===========================================
// パンくず
// ===========================================
.breadcrumbs {
  display: flex;
  align-items: center;

  @include g.mq() {
    max-width: g.$g-width-md;
    margin: 36px auto 0;
    padding: 0;
  }
}

.c-breadcrumb-item {
  font-size: 1.3rem;
  color: g.$g-text-blackright;
  
  &:not(:first-of-type) {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: block; 
      width: 15px;
      height: 15px;
      fill: g.$g-bg-blue;
      background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxyZWN0IHg9IjUuOTQ5MjIiIHk9IjMuNzQ5NTEiIHdpZHRoPSI1LjA1MDUiIGhlaWdodD0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNS45NDkyMiAzLjc0OTUxKSIvPg0KPHJlY3QgeD0iNS4zNTQiIHk9IjEwLjU0MzIiIHdpZHRoPSI1LjIyNzgzIiBoZWlnaHQ9IjEiIHRyYW5zZm9ybT0icm90YXRlKC00NSA1LjM1NCAxMC41NDMyKSIvPg0KPC9zdmc+DQo=+PC9zdmc+");
    }
  }

  a {
    font-weight: bold;
    color: g.$g-bg-blue;

    @include g.mq() {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}