@use "../global" as g;


// =========================================
// MV
// =========================================
.p-mv {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);

  position: relative;
  background: url("../images/top_mv_sp.svg") top 20px center no-repeat;
  background-size: contain;

  @media screen and (min-width: 750px) {
    background: url("../images/top_mv_pc.svg") center center no-repeat;
    background-size: cover;
  }
  @include g.mq() {
    min-height: 90vh;
    padding-top: 0;
    background: url("../images/top_mv_pc.svg") center center no-repeat;
    background-size: cover;
  }
}
.p-mv-desc {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;

  @include g.mq() {
    width: g.vw(1048);
    max-width: g.$g-width-md;
    margin: 0 auto;
  }
}

.p-mv-descInner {
  padding: 0 20px;

  @include g.mq() {
    padding: 0;
  }
}
.p-btn-scroll {
  position: absolute;
  bottom: 70px;
  right: 20px;

  @include g.mq() {
    bottom: 8px;
    right: auto;
    left: 650px;
  }

  .c-btn-circle {
    position: relative;

    @include g.mq() {
      transform: translateY(0);
      transition: all g.$g-transition-btn;
      &:hover {
        transform: translateY(-2px);
      }
    }

    &::before {
      content: "Scroll";
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1.2rem;
      font-weight: bold;

      @include g.mq() {
        top: -24px;
        letter-spacing: 0.1px;
        color: g.$g-text-primary;
      }
    }
  }
  svg {
    transform: rotate(90deg);
  }
}

// =========================================
// News
// =========================================
.p-newsTop {
  overflow: hidden;

  @include g.mq() {
    padding-top: 50px;
    margin-top: -50px;
  }
}
.p-newsTop-inner {
  position: relative;
  padding: 0 g.$g-padding;
  margin-bottom: 146px;

  @include g.mq() {
    width: g.vw(1080);
    max-width: g.$g-width-md;
    padding: 0;
    margin: 0 auto 180px;

    &::before {
      content: "";
      position: absolute;
      top: -72px;
      right: 22px;
      z-index: 1;
      width: 142px;
      height: 118px;
      background: url("../images/illust_01.svg") 0 0 no-repeat;
    }

    &::after {
      content: "";
      position: absolute;
      top: 190px;
      left: -10vw;
      width: 200px;
      height: 200px;
      background: url("../images/decoration/bg_sousyoku04.svg") 0 0 no-repeat;
    }
  }
}
.p-newsTop-title {
  position: absolute;
  top: -26px;
  left: 42px;
  z-index: g.z(top-news-title);
  font-size: 4.4rem;
  font-weight: 100;
  line-height: 1;

  @include g.mq() {
    top: 70px;
    left: 4px;
    // font-size: 8rem;
    font-size: 7.4vw;
  }
  @include g.mq-lg() {
    font-size: 8rem;
  }
}
.p-newsTop-container {
  position: relative;
  padding: 40px 20px 24px 26px;
  margin-top: 35px;
  border: 1px solid g.$g-border-gray1;
  box-shadow: g.$g-elevation01;
  background-color: #ffffff;

  @include g.mq() {
    display: flex;
    width: g.vw(973);
    max-width: 989px;
    padding: 24px 40px 32px;
    // margin-left: auto;
    margin-left: 86px;
  }

  &:not(:first-of-type) {
    margin-top: 24px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: -1000;
    width: 100%;
    height: 100%;
    background: url('../images/bgcheck01.png') 0 0 repeat;

    @include g.mq() {
      height: 300px;
      top: 40px;
      left: 120px;
    }
  }
}

.p-newsTop-header {
  padding-bottom: 24px;
  border-bottom: 1px solid g.$g-border-gray1;

  @include g.mq() {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    flex-shrink: 0;
    width: g.vw(252);
    max-width: 252px;
    padding-right: 56px;
    padding-bottom: 0;
    border-right: 1px solid g.$g-border-gray1;
    border-bottom: none;
  }
}
.p-newsTop-headerLink {
  display: flex;
  // justify-content: space-between;
  margin-bottom: 18px;

  @include g.mq() {
    display: block;
    margin-bottom: 20px;
  }
}
.p-newsTop-headerItem {
  &:not(:first-of-type) {
    margin-left: 8px;

    @include g.mq() {
      margin-left: 0;
      margin-top: 12px;
    }
  }
}
.p-newsTop-headerItemLink {
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 36px;
  text-align: center;
  width: 24.26vw;
  max-width: 120px;
  height: 36px;
  border-radius: 100vw;
  box-shadow: g.$g-elevation02;
  transition: g.$g-transition-btn;

  &:hover {
    box-shadow: none;
    border-width: 1px;
    border-style: solid;;
    background-color: #ffffff;
  }

  &--offering {
    background-color: g.$g-bg-green;
    &:hover {
      color: g.$g-bg-green;
      border-color: g.$g-bg-green;
    }
  }
  &--general {
    color: g.$g-text-blackright;
    background-color: g.$g-bg-orange;
    &:hover {
      // color: g.$g-bg-orange;
      border-color: g.$g-bg-orange;
    }
  }
  &--event {
    background-color: g.$g-bg-red;
    &:hover {
      color: g.$g-bg-red;
      border-color: g.$g-bg-red;
    }
  }
}


.p-newsTop-body {
  padding-top: 22px;

  @include g.mq() {
    width: 100%;
    padding: 0 0 0 40px;
  }
}
.p-newsTop-bodyHeader {
  font-size: 2.4rem;
  font-weight: bold;
  color: g.$g-text-nabyblue;
  line-height: 34px;
  margin-bottom: 18px;
}
.p-newsTop-bodyItem {
  @include g.mq() {
    display: flex;
    align-items: center;
  }

  &:not(:last-of-type) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dashed g.$g-border-gray1;

    @include g.mq() {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
  }
}
.p-newsTop-bodyItemWrap {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  @include g.mq() {
    margin-bottom: 0;
    margin-right: 56px;
  }
}
.p-newsTop-bodyTime {
  color: g.$g-text-gray;
  font-size: 1.3rem;
  line-height: 1;
  width: 72px;
  margin-right: 24px;
}
.p-newsTop-bodyCate {
  font-size: 1.1rem;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  line-height: 25px;
  width: 70px;
  height: 24px;
  border-radius: 100vw;

  &--offering {
    background-color: g.$g-bg-green;
  }
  &--general {
    color: g.$g-text-blackright;
    background-color: g.$g-bg-orange;
  }
  &--event {
    background-color: g.$g-bg-red;
  }
}
.p-newsTop-bodyLink {
  a {
    line-height: 1.7;
    text-decoration: underline;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @include g.mq() {
      -webkit-line-clamp: 2;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
.p-newsTop-bodyLink--en {
  a {
    text-align: left;
  }
}


// =========================================
// Calendar
// =========================================
.p-calender {
  position: relative;
  overflow: hidden;
  padding: 0 g.$g-padding;
  margin-bottom: 146px;

  @include g.mq() {
    overflow: visible;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: g.vw(1048);
    max-width: g.$g-width-md;
    padding: 0;
    margin: 0 auto 180px;
  }

  &::before {
    content: "";
    position: absolute;
    top: -80px;
    right: -10px;
    z-index: -1000;
    display: block;
    width: 177px;
    height: 177px;
    background: url("../images/decoration/bg_sousyoku01.svg") 0 0 no-repeat;

    @include g.mq() {
      top: -40px;
      right: auto;
      left: 210px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -120px;
    left: -10px;
    z-index: -1000;
    display: block;
    width: 170px;
    height: 166px;
    background: url("../images/decoration/bg_sousyoku02.svg") 0 0 no-repeat;

    @include g.mq() {
      bottom: -50px;
      left: -7vw;
    }
  }
}
.p-calender-header {
  margin-bottom: 24px;

  @include g.mq() {
    width: 23.407vw;
    min-width: 350px;
    margin-bottom: 0;
  }
}
.p-calender-title {
  font-size: 4.4rem;
  font-weight: 100;
  line-height: 62px;

  @include g.mq() {
    font-size: 8rem;
    line-height: 112px;
  }
}
.p-calender-titleJp {
  position: relative;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: .7px;
  color: g.$g-text-nabyblue;
  padding-left: 56px;
  margin-bottom: 20px;

  @include g.mq() {
    letter-spacing: 3.3px;
    font-size: 2.4rem;
    padding-left: 64px;
    margin-bottom: 30px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 48px;
    height: 2px;
    background-color: g.$g-bg-red;
  }
}
.p-calender-desc {
  @include g.mq() {
    font-size: 1.5rem;
  }
}
.p-calender-body {
  @include g.mq() {
    flex-shrink: 0;
    width: 58.14%;
    max-width: 628px;
  }
}


// =========================================
// 背景用
// =========================================
.p-project-vi {
  background: url("../images/decoration/bg_grid01.svg") top -10px center no-repeat;
  background-size: 300%;

  @include g.mq() {
    background: url("../images/decoration/bg_grid01.svg") top center no-repeat;
    background-size: 100%;
  }
}
// =========================================
// Project & Menber
// =========================================
.p-project {
  position: relative;
  padding: 0 g.$g-padding;
  margin-bottom: 146px;

  @include g.mq() {
      padding: 0;
    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 20px;
      left: 0;
      width: 104px;
      height: 180px;
      background: url("../images/decoration/bg_sousyoku03.svg") 0 0 no-repeat;
    }
  }
}
.p-project-inner {
  @include g.mq() {
    width: g.vw(1048);
    max-width: g.$g-width-md;
    margin: 0 auto;
  }
}

.p-project-title {
  font-size: 4.2rem;
  font-weight: 100;
  line-height: 62px;
  letter-spacing: -0.5px;

  @include g.mq() {
    text-align: center;
    font-size: 8rem;
    line-height: 112px;
  }
}
.p-project-titleJp {
  position: relative;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: .7px;
  color: g.$g-text-nabyblue;
  padding-left: 56px;
  margin-bottom: 20px;

  @include g.mq() {
    letter-spacing: 3.3px;
    text-align: center;
    font-size: 2.4rem;
    padding-left: 0;
    padding-bottom: 56px;
    margin-bottom: 40px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 48px;
    height: 2px;
    background-color: g.$g-bg-orange;

    @include g.mq() {
      top: auto;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 48px;
    }
  }
}

.p-project-grid-desc { grid-area: desc; }
.p-project-grid-general { grid-area: general; }
.p-project-grid-a01 { grid-area: a01; }
.p-project-grid-a02 { grid-area: a02; }
.p-project-grid-b01 { grid-area: b01; }
.p-project-grid-b02 { grid-area: b02; }
.p-project-grid-b03 { grid-area: b03; }
.p-project-grid-b04 { grid-area: b04; }
.p-project-body {
  color: g.$g-text-blackright;


  @include g.mq() {
    // display: flex;
    // flex-wrap: wrap;
    // gap: 24px;

    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    gap: 24px 24px;
    grid-template-areas: 
      "desc desc general"
      "a01 a02 b01"
      "b02 b03 b04"; 
  }
  @include g.mq-lg() {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 24px 24px; 
    grid-template-areas: 
      "desc general a01 a02"
      "b01 b02 b03 b04"; 
  }
}

.p-project-bodyDesc {
  margin-bottom: 80px;

  @include g.mq() {
    letter-spacing: 0.3px;
    // width: 249px;
    height: 312px;
    padding: 0 0 0 20px;
    margin: 0;

    display: flex;
    align-items: center;

    @at-root .en .p-project-bodyDesc {
      @include g.mq() {
        height: 367px;
      }
    }
  }

  p {
    margin-bottom: 20px;

    @at-root .p-project-bodyDesc p {
      @include g.mq() {
        line-height: 1.4;
      }
    }
  }

  p > a {
    text-decoration: underline;

    @include g.mq() {
      color: g.$g-bg-blue;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
.p-project-card {
  margin-bottom: 12px;
  @include g.mq() {
    margin-bottom: 0;
  }

  a {
    display: block;
    color: #ffffff;
    padding: 20px;
    background: g.$g-text-nabyblue top right no-repeat;

    @include g.mq() {
      position: relative;
      // width: 249px;
      height: 312px;
      transform:translateY(0);
      transform-origin: center bottom;
      box-shadow: none;
      transition: all g.$g-transition-btn;

      @at-root .en .p-project-card a {
        height: 367px;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: g.$g-elevation02;
      }
    }
  }

  &:nth-of-type(2) a {
    background-image: url("../images/card/card_bg_01.svg");
  }
  &:nth-of-type(3) a {
    background-image: url("../images/card/card_bg_02.svg");
  }
  &:nth-of-type(4) a {
    background-image: url("../images/card/card_bg_03.svg");
  }
  &:nth-of-type(5) a {
    background-image: url("../images/card/card_bg_04.svg");
  }
  &:nth-of-type(6) a {
    background-image: url("../images/card/card_bg_05.svg");
  }
  &:nth-of-type(7) a {
    background-image: url("../images/card/card_bg_03.svg");
  }
  &:nth-of-type(8) a {
    background-image: url("../images/card/card_bg_06.svg");
  }
  
}
.p-project-cardTitle {
  display: flex;
  align-items: center;
  font-size: 4.6rem;
  line-height: 1;
  font-weight: 300;
  margin-bottom: 24px;

  span {
    display: inline-block;
    font-size: 2.8rem;
    margin-right: 8px;
  }
}
.p-project-cardSubTtl {
  font-weight: bold;
  margin-bottom: 12px;
  text-align: justify;

  @at-root .en .p-project-cardSubTtl {
    @include g.mq() {
      text-align: left;
    }
  }
}
.p-project-cardRepresentative {
  font-size: 1.2rem;

  @include g.mq() {
    position: absolute;
    top: 190px;
    left: 22px;
    right: 22px;

    @at-root .en .p-project-cardRepresentative {
      @include g.mq() {
        top: 216px;
      }
    }
  }
}
.p-project-cardArrowWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  @include g.mq() {
    position: absolute;
    bottom: 22px;
    right: 22px;
  }
}


// =========================================
// Visual Identity
// =========================================
.p-vi {
  padding: 0 g.$g-padding;
}
.p-vi-title {
  font-size: 4.4rem;
  font-weight: 100;
  line-height: 62px;

  @include g.mq() {
    text-align: center;
    font-size: 8rem;
    line-height: 112px;
  }
}
.p-vi-titleJp {
  position: relative;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: .7px;
  color: g.$g-text-nabyblue;
  padding-left: 56px;
  margin-bottom: 20px;

  @include g.mq() {
    letter-spacing: 3.3px;
    text-align: center;
    font-size: 2.4rem;
    padding-left: 0;
    padding-bottom: 56px;
    margin-bottom: 40px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 48px;
    height: 2px;
    background-color: g.$g-bg-green;

    @include g.mq() {
      top: auto;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 48px;
    }
  }
}
.p-vi-body {
  text-align: center;

  @include g.mq() {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: g.vw(627);
    max-width: 627px;
    margin: 0 auto;
  }
}
.p-vi-bodyLogo {
  text-align: center;
  margin-bottom: 24px;

  @include g.mq() {
    flex-shrink: 0;
    width: g.vw(288);
    max-width: 200px;
    margin-right: 40px;
    margin-bottom: 0;
  }
  img {
    width: 76.8%;
    max-width: 160px;

    @include g.mq() {
      width: 100%;
      max-width: none;
    }
  }
}
.p-vi-bodyDesc {
  letter-spacing: 1px;
  text-align: left;
  color: g.$g-text-blackright;
}