/**
 * font-family.
 */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Noto+Sans+JP:wght@400;700&display=swap');
$g-font-serif: 'Lato', 'Noto Sans JP', sans-serif;

// Noto Sans JP / Bold:700, Regular:400
// lato         / Bold:700, Regular:400

/**
 * font-size.
 */
$g-fz-md: 1.4rem;
$g-fz-lg: 2.2rem;
$g-fz-xl: 3.4rem;
  
/**
 * color.
 */
$g-text-primary: #2B2A25;
$g-text-blackright: #3D3D3D;
$g-text-nabyblue: #11294F;
$g-text-gray: #A0A0A0;

$g-text-white: #FFFFFF;

$g-border-gray1: #E2E2E2;
$g-border-gray2: #CACACA;

$g-bg-whitegray: #F8F8F8;
$g-bg-rightgray: #F2F2F2;
$g-bg-gray:      #E2E2E2;
$g-bg-red:       #F55A62;
$g-bg-orange:    #F1DD17;
$g-bg-green:     #2A94B7;
$g-bg-blue:      #286EA8;
$g-bg-rightblue: #E5F3FA;

$g-btn-orange-hover: #F08514;


$g-graduation_white01: linear-gradient(180deg, rgba(236,236,236,1) 10%, rgba(236,236,236,0) 100%);
$g-graduation_white02: linear-gradient(180deg, rgba(236,236,236,1) 40%, rgba(236,236,236,0) 100%);

$g-graduation_gray01: linear-gradient(135deg, rgba(242,242,242,1) 0%, rgba(242,242,242,0.2) 100%);
$g-graduation_gray02: linear-gradient(300deg, rgba(236,236,236,1) 0%, rgba(246,246,246,0.3) 100%);

$g-graduation_all: linear-gradient(90deg, rgba(245, 90, 98, 1) 0%, rgba(245, 90, 98, 1) 25%, rgba(241, 221, 23, 1) 25%, rgba(241, 221, 23, 1) 50%, rgba(42, 148, 183, 1) 50%, rgba(42, 148, 183, 1) 75%, rgba(40, 110, 168, 1) 75%, rgba(40, 110, 168, 1) 100%);;

/**
 * width.
 */
$g-width-xs: 686px;
$g-width-sm: 716px;
$g-width-md: 1080px;

$g-width-sp: 89.33%;

/**
 * Padding.
 */
 $g-padding: 20px;

/**
 * Elevation.
 */
$g-elevation01: 0 10px 20px rgba(0, 0, 0, 0.05);
$g-elevation02: 0 5px 10px rgba(0, 0, 0, 0.2);
$g-elevation03: 0 1px 10px rgba(0, 0, 0, 0.15);

/**
 * transition.
 */
$g-transition-btn: .0925s ease-in;
$g-transition-input: .12s ease-out;
$g-transition-cubic-bezier: .175s cubic-bezier(0.65, -0.31, 0.41, 1.38);
