@use "../global" as g;


// =========================================
// 本領域の研究集会
// =========================================
.p-meeting {
  padding: 0 g.$g-padding;
  @include g.mq() {
    padding: 0;
  }
}

.p-meeting-inner {
  @include g.mq() {
    width: g.vw(1048);
    max-width: g.$g-width-md;
    margin: 0 auto;
  }
}

.p-meeting-iframe {
  iframe {
    width: 100%;

    th {
      background-color: g.$g-bg-blue !important;
    }
  }
}

.p-meeting-iframeTtl {
  position: relative;
  font-size: 2.2rem;
  font-weight: bold;
  color: #11294f;
  line-height: 1.4;
  position: relative;
  padding-bottom: 17px;
  margin-bottom: 40px;

  @include g.mq() {
    font-size: 2.6rem;
    margin-bottom: 40px;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: g.$g-border-gray1;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 224px;
    height: 5px;
    background: g.$g-graduation_all;
  }
}