@use "../global" as g;

// 小さな丸いブルーのボタン
.c-btn-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 100vw;
  background-color: g.$g-bg-blue;
  box-shadow: g.$g-elevation02;

  transition: g.$g-transition-btn;

  svg,
  .arrow01 {
    fill: #ffffff;
  }

  &:hover {
    transform: translateY(-2px);
  }
}

// お知らせボタン PC
.c-btn-category-all {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 90px;
  height: 36px;
  background-color: g.$g-bg-blue;
  border-radius: 100vw;
  box-shadow: g.$g-elevation02;
  @include g.mq() {
    width: 102px;
  }

  &--active {
    color: g.$g-bg-blue;
    border: 1px solid g.$g-bg-blue;
    background-color: #ffffff;
    box-shadow: none;
  }
}
.c-btn-category-event {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 90px;
  height: 36px;
  background-color: g.$g-bg-red;
  border-radius: 100vw;
  box-shadow: g.$g-elevation02;
  @include g.mq() {
    width: 102px;
  }

  &--active {
    color: g.$g-bg-red;
    border: 1px solid g.$g-bg-red;
    background-color: #ffffff;
    box-shadow: none;
  }
}
.c-btn-category-general {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 90px;
  height: 36px;
  background-color: g.$g-bg-orange;
  border-radius: 100vw;
  box-shadow: g.$g-elevation02;
  @include g.mq() {
    width: 102px;
  }

  &--active {
    color: g.$g-bg-orange;
    border: 1px solid g.$g-bg-orange;
    background-color: #ffffff;
    box-shadow: none;
  }
}
.c-btn-category-offering {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 90px;
  height: 36px;
  background-color: g.$g-bg-green;
  border-radius: 100vw;
  box-shadow: g.$g-elevation02;
  @include g.mq() {
    width: 102px;
  }

  &--active {
    color: g.$g-bg-green;
    border: 1px solid g.$g-bg-green;
    background-color: #ffffff;
    box-shadow: none;
  }
}


// =====================================
// View More
// =====================================
.c-btn-viewMore {
  a {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-decoration: none;
    transition: all g.$g-transition-btn;

    @include g.mq() {
      .c-btn-circle {
        transform: translateX(0);
        transition: all g.$g-transition-btn;
      }
      &:hover {
        .c-btn-circle {
          transform: translateX(2px);
        }
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
.c-btn-viewMoreLink {
  overflow: hidden;
    position: relative;
    color: g.$g-text-nabyblue;
    font-size: 1.6rem;
    line-height: 1;
    font-weight: bold;
    padding-bottom: 8px;
    margin-right: 8px;

    @include g.mq() {
      color: g.$g-text-primary;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: g.$g-bg-blue;

      @include g.mq() {
        height: 1px;
      }
    }
}