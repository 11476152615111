@use "../global" as g;


// =========================================
// メンバー
// =========================================
.p-member {
  padding: 0 g.$g-padding;
  @include g.mq() {
    overflow: hidden;
    padding: 2px 0 0;
    margin-top: -2px;
  }
}

.p-member-inner {
  @include g.mq() {
    width: g.vw(1048);
    max-width: g.$g-width-md;
    margin: 0 auto;
  }
}

.p-member-body {
  color: g.$g-text-blackright;

  // @include g.mq() {
  //   display: flex;
  //   flex-wrap: wrap;
  //   gap: 24px;
  //   margin-bottom: 80px;
  // }

  @include g.mq() {
    // display: flex;
    // flex-wrap: wrap;
    // gap: 24px;

    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    gap: 24px 24px;
    grid-template-areas: 
      "desc desc general"
      "a01 a02 b01"
      "b02 b03 b04"; 
    margin-bottom: 80px;
  }
  @include g.mq-lg() {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 24px 24px; 
    grid-template-areas: 
      "desc general a01 a02"
      "b01 b02 b03 b04"; 
  }
}


// ==================================================
// 湊先生
// ==================================================
.p-member-bodyDesc {
  margin: 0 auto 40px;

  @include g.mq() {
    display: flex;
    // justify-content: center;
    align-items: center;
    letter-spacing: 0.3px;
    // width: 249px;
    height: 312px;
    padding: 0;
    margin: 0;
  }
}
.p-member-professorName {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-member-professorAvatar {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin-right: 12px;

  img {
    border-radius: 100vw;
  }
}
.p-member-professorText {
}
.p-member-professorRole {
  display: block;
  font-size: 1.8rem;
  font-weight: bold;

  @include g.mq() {
    font-size: 2.2rem;
  }
}
.p-member-professorLink {
  font-size: 1.8rem;
  font-weight: bold;

  > span {
    text-decoration: underline;

    @include g.mq() {
      color: g.$g-bg-blue;
    }
  }
  @include g.mq() {
    &:hover {
      > span {
        text-decoration: none;
      }
    }
  }

  svg {
    width: 18px;
    height: 16px;
    fill: g.$g-bg-blue;
  }
}
.p-member-professorCalle {
  font-size: 1.5rem;

  @at-root .en .p-member-professorCalle {
    display: block;
  }
}

// ==================================================
// Cards Sp
// ==================================================
.p-memberBodySp {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @include g.mq() {
    display: none;
  }
}
.p-memberBodySp-item {
  flex-shrink: 0;
}
.p-memberBodySp-itemLink {
  display: block;
  color: #ffffff;
  width: 28.26666667vw;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: g.$g-text-nabyblue;
  box-shadow: g.$g-elevation03;

  @at-root .en .p-memberBodySp-itemLink {
    font-size: 1.4rem;
    line-height: 1.2;
    padding-left: 12px;
    padding-right: 4px;

    @include g.mq() {
      font-size: 1.5rem;
      line-height: 1.7;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.p-member-cardArrowSp {
  display: flex;
  align-items: center;
  margin-left: 4px;
  svg {
    fill: #ffffff;
    transform: rotate(90deg);
    margin-top: 2px;
  }
}

// ==================================================
// Cards Pc
// ==================================================
.p-member-grid-desc { grid-area: desc; }
.p-member-grid-general { grid-area: general; }
.p-member-grid-a01 { grid-area: a01; }
.p-member-grid-a02 { grid-area: a02; }
.p-member-grid-b01 { grid-area: b01; }
.p-member-grid-b02 { grid-area: b02; }
.p-member-grid-b03 { grid-area: b03; }
.p-member-grid-b04 { grid-area: b04; }

.p-member-card {
  display: none;
  margin-bottom: 12px;
  @include g.mq() {
    display: block;
    margin-bottom: 0;
  }

  a {
    display: block;
    color: #ffffff;
    padding: 20px;
    background: g.$g-text-nabyblue top right no-repeat;

    @include g.mq() {
      position: relative;
      // width: 249px;
      height: 312px;
      transform:translateY(0);
      box-shadow: none;
      transition: all g.$g-transition-btn;

      &:hover {
        transform: translateY(-2px);
        box-shadow: g.$g-elevation02;
      }
    }
  }

  &:nth-of-type(2) a {
    background-image: url("../images/card/card_bg_01.svg");
  }
  &:nth-of-type(3) a {
    background-image: url("../images/card/card_bg_02.svg");
  }
  &:nth-of-type(4) a {
    background-image: url("../images/card/card_bg_03.svg");
  }
  &:nth-of-type(5) a {
    background-image: url("../images/card/card_bg_04.svg");
  }
  &:nth-of-type(6) a {
    background-image: url("../images/card/card_bg_05.svg");
  }
  &:nth-of-type(7) a {
    background-image: url("../images/card/card_bg_03.svg");
  }
  &:nth-of-type(8) a {
    background-image: url("../images/card/card_bg_06.svg");
  }
  
}
.p-member-cardTitle {
  display: flex;
  align-items: center;
  font-size: 4.6rem;
  line-height: 1;
  font-weight: 300;
  margin-bottom: 24px;

  span {
    display: inline-block;
    font-size: 2.8rem;
    margin-right: 8px;
  }
}
.p-member-cardSubTtl {
  font-weight: bold;
  margin-bottom: 12px;
}
.p-member-cardRepresentative {
  font-size: 1.2rem;

  @include g.mq() {
    position: absolute;
    top: 190px;
    left: 22px;
    right: 22px;
  }
}
.p-member-cardArrowWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  @include g.mq() {
    position: absolute;
    bottom: 22px;
    right: 22px;
  }

  svg {
    transform: rotate(90deg);
  }
}


// ==================================================
// Section
// ==================================================
.p-member-section {
  margin-bottom: 80px;

  &:not(:last-of-type) {
    @include g.mq() {
      margin-bottom: 120px;
    }
  }
}

.p-member-secGroupTtl {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 6.6rem;
  font-weight: 300;
  line-height: 1.25;
  height: 80px;
  padding: 0 20px;
  margin-bottom: 20px;
  margin-right: calc(50% - 50vw);
	margin-left: calc(50% - 50vw);
  background-color: g.$g-text-nabyblue;

  @include g.mq() {
    overflow: visible;
    margin-bottom: 40px;
    margin-right: auto;

    &::before {
      content: "";
      position: absolute;
      bottom: -150px;
      right: -13vw;
      z-index: -1;
      opacity: .8;
      display: block;
      width: 200px;
      height: 200px;
      background: url("../images/decoration/bg_sousyoku04.svg") 0 0 no-repeat;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 100px;
    display: block;
    width: 1px;
    height: 600px;
    background-color: #ffffff;
    transform: rotate(45deg);
    transform-origin: 0 0;
  }

  &--jp {
    font-size: 2.6rem;
    font-weight: bold;

    @include g.mq() {
      margin-bottom: 80px;
    }
  }

  > span {
    font-size: 4.6rem;
    font-weight: 300;
    margin-right: 12px;
  }
}
.p-member-secTtl {
  position: relative;
  color: g.$g-text-nabyblue;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.4;
  padding-bottom: 12px;
  margin-bottom: 40px;
  @include g.mq() {
    font-size: 2.4rem;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: g.$g-border-gray1;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 224px;
    height: 5px;
    background: g.$g-graduation_all;
  }

  &--num {
    display: block;
    font-size: 4.6rem;
    line-height: 1.4;
    font-weight: 300;
    color: g.$g-border-gray2;

    @include g.mq() {
      font-size: 6.6rem;
    }
  }
}

.p-member-groupDelegate {
  display: flex;

  @include g.mq() {
    align-items: center;
  }

  @at-root .en .p-member-groupDelegate {
    display: block;

    @include g.mq() {
      display: flex;
    }
  }
}
.p-member-groupTtl {
  font-weight: bold;
  font-size: 1.8rem;
  white-space: nowrap;

  @include g.mq() {
    font-size: 2.2rem;
  }

  span {
    font-size: 1.5rem;
    margin-left: 10px;
  }
}

.p-member-groupBodyDelegate {
  text-align: left;
  margin-left: 20px;

  @at-root .en .p-member-groupBodyDelegate {
    margin-left: 0;

    @include g.mq() {
      margin-left: 20px;
    }
  }
}
.p-member-groupBodyDelegateWrap {
  display: flex;
  flex-wrap: wrap;
}
.p-member-groupBodyDelegateLink {
  color: g.$g-bg-blue;
  font-weight: bold;
  margin-right: 4px;

  @at-root .en .p-member-groupBodyDelegateLink {
    display: block;

    @include g.mq() {
      display: inline-block;
    }
  }

  svg {
    width: 18px;
    height: 16px;
    fill: g.$g-bg-blue;
  }

  &:hover {
    .p-member-groupBodyDelegateName {
      text-decoration: none;
    }
  }
  
}
.p-member-groupBodyDelegateName {
  font-size: 1.8rem;
  white-space: nowrap;
  text-decoration: underline;
}
.p-member-groupBodyDelegateCollege {
  white-space: nowrap;
}


.p-member-group {
  margin-bottom: 40px;
  @include g.mq() {
    margin-bottom: 40px;
  }

  &:last-of-type {
    margin-bottom: 80px;
  }
}

$js-memberAccordionHiehgt: 140px;
.p-member-groupBody {
  margin-top: 10px;

  // アコーディオン用

  &.js-memberAccordion {
    // アコーディオン用
    height: $js-memberAccordionHiehgt;
    position: relative;     /*開閉ボタン固定配置の基準点用*/
    overflow: hidden;       /*決めた高さまでで非表示*/
    transition: all .5s; /* 開閉アニメーション用 */

    .p-member-list {
      // アコーディオン用
      height: auto;           /*全体の高さを取得するため*/
      position: absolute;     /*全体の高さを取得するため*/
    }
  }
}
.p-member-list {
  display: flex;
  flex-direction: column;

  @include g.mq() {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px 3vw;
  }
}
.p-member-item {
  position: relative;
  font-size: 1.3rem;
  line-height: 1.4;
  letter-spacing: 0.4px;
  padding-left: 12px;

  @include g.mq() {
    font-size: 1.5rem;
    width: calc(33% - 36px)
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;

    @include g.mq() {
      margin-bottom: 0;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 0;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background-color: g.$g-bg-blue;
  }
}
.p-member-OpenBtn {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: g.z(p-member-OpenBtn);
  display: flex;
  align-items: flex-end;

  width: 100%;
  height: $js-memberAccordionHiehgt;
  background: linear-gradient(0deg, rgba(255,255,255,1) 34%, rgba(255,255,255,0.4) 100%);
  cursor: pointer;

  > .c-btn-circle {
    position: relative;
    margin: 0 auto 10px;

    &::before {
      content: "More";
      display: block;
      position: absolute;
      top: -22px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: bold;
      font-size: 1.2rem;
    }
  }
}

.p-member-offering {
  @include g.mq() {
    display: flex;
    width: 898px;
  }
  &:not(:last-of-type) {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid g.$g-border-gray1;
  }
}
.p-member-offeringTtl {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 12px;

  @include g.mq() {
    font-size: 2.2rem;
    width: 182px;
    flex-shrink: 0;
  }
}
.p-member-offeringText {
  a {
    color: g.$g-bg-blue;
    text-decoration: underline;
  }
}
.p-member-offeringGrp {
  padding: 24px 22px;
  margin-top: 24px;
  background-color: g.$g-bg-whitegray;

  @include g.mq() {
    padding: 24px 40px;
  }
}
.p-member-offeringGrpTtl {
  font-size: 4.6rem;
  font-weight: 300;
  line-height: 1.4;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  span {
    display: inline-block;
    font-size: 2.8rem;
    margin-right: 8px;
  }
}
.p-member-offeringGrpWrap {
  @include g.mq() {
    display: flex;
    margin-bottom: 12px;
  }
  & + & {
    margin-top: 18px;
    @include g.mq() {
      margin-top: 0;
    }
  }
}
.p-member-offeringGrpHead {
  margin-bottom: 4px;

  @include g.mq() {
    flex-shrink: 0;
    width: 114px;
  }
}
.p-member-offeringCaption {
  margin-top: 24px;
}